import * as dialogs from "./dialogs";
function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');


    } catch (err) {

    }

    document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {

    }, function(err) {

    });
}
window.hasClass = function (el, className) {
    if (el.classList) {
        return el.classList.contains(className);
    } else {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    }
}
window.removeClass = function (el, className) {

    if (el.classList)
        el.classList.remove(className);
    else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}
window.addClass = function (el, className) {

    if (el.classList)
        el.classList.add(className);
    else
        el.className += ' ' + className;
}

let authForm = document.getElementById('ax-form');
let usernameField = authForm.querySelector("[name=usr]");
let passwordField = authForm.querySelector("[name=ac]");
let submitButton = authForm.querySelector("[type=submit]");
let downloadButton = document.querySelector(".download-app");
let downloadDialog = document.querySelector(".dialog.download");
let languageSwitchButton = document.querySelector(".toolbox-button.language-switch");
let  languageDropdown = document.querySelector(".dropdown-menu.languages");
let  languageOptions = document.querySelectorAll(".language-option");
let copyQuickLinkButton = downloadDialog.querySelector('.copy-quick-link');
let regionSwitcherButtons = document.querySelectorAll('.region-switcher');

languageSwitchButton.addEventListener('click', e => {
    if (hasClass(languageDropdown, 'on')) {
        removeClass(languageDropdown, 'on');

    } else {
        addClass(languageDropdown, 'on');
    }
    e.target.classList.toggle('on');
});
copyQuickLinkButton.addEventListener("click", e => {
    copyTextToClipboard('https://health.zbkbalkan.com/app/');
});

languageOptions.forEach((button) => {
    button.addEventListener('click', () => {


            if (hasClass(button, 'on')) {
                removeClass(languageDropdown, 'on');
                removeClass(languageSwitchButton, 'on');
            }   else {
                addClass(button, 'on');
                removeClass(languageDropdown, 'on');
                removeClass(languageSwitchButton, 'on');
                let xhr = new XMLHttpRequest();
                xhr.open("GET", '/locale/'+ button.dataset.code +'/', true);
                xhr.onreadystatechange = function () {
                    if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                        window.location.replace("http://health.zbkbalkan.com/");
                    }
                };
                xhr.send();
            }




    });
});



regionSwitcherButtons.forEach((button) => {
    button.addEventListener('click', () => {

        let xhr = new XMLHttpRequest();
        xhr.open("GET", '/sr/?r=' + button.dataset.region, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                window.location.replace("http://health.zbkbalkan.com/");
            }
        };
        xhr.send();



    });
});




downloadButton.addEventListener('click', e => {
    addClass(downloadDialog, 'on');
});

dialogs.prepareDialogRequisites();

window.addEventListener("click", e => {
    let classList = event.target.classList;
    if (!classList.contains('languages') && !classList.contains('language-switch')) {
        if (hasClass(languageDropdown, 'on')) {
            removeClass(languageDropdown, 'on');
            removeClass(languageSwitchButton, 'on');
        }
    }
});

authForm.addEventListener('submit', function (event) {
    event.preventDefault();
    if (passwordField.value != "" && usernameField.value != "") {

        let submitValue = submitButton.value;

        submitButton.value = "";

        addClass(submitButton, 'loading');

        event.preventDefault();

        let formData = new FormData(authForm);

        usernameField.disabled = true;
        submitButton.disabled = true;
        passwordField.disabled = true;

        let xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open("POST", '/ax/', true);
        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                switch (xhr.response.status)
                {
                    case 1:
                        window.location.replace("http://health.zbkbalkan.com/dashboard/");
                    case -1:
                        usernameField.disabled = false;
                        submitButton.disabled = false;
                        passwordField.disabled = false;
                        submitButton.value = submitValue;
                        removeClass(submitButton, 'loading');
                        break;
                }
 
            }
        };
        xhr.send(formData);

    }
});